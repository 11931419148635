import Home from "../Home/Home";
import About from "../About/About";
import Services from "../Services/Services";
import Audit from "../Audit/Audit";
import Contact from "../Contact/Contact";

function Path(component, url){
    this.component = component;
    this.url = url;
}

const routePaths = [
    new Path(About, "/o-nas"),
    new Path(Services, "/uslugi"),
    new Path(Audit, "/audyt"),
    new Path(Contact, "/kontakt"),
    new Path(Home, "/")
  ];
// Home has to be the last because of how <Switch> works

export default routePaths;