function PathLink(name, url){
    this.name = name;
    this.url = url;
}

const pathLinks = [
    new PathLink("Strona Główna", "/"),
    new PathLink("O nas", "/o-nas"),
    new PathLink("Usługi", "/uslugi"),
    new PathLink("Audyt", "/audyt"),
    new PathLink("Kontakt", "/kontakt")
]

export default pathLinks;