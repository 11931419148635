import {useState} from "react";

import {Link} from "react-router-dom";
import {HiMenu} from "react-icons/hi";
import {GrFormClose} from "react-icons/gr";

import pathLinks from '../Utils/pathLinks';

import agmLogo from "./img/agm-logo.jpg";
import "./Nav.css";

export default function Nav(){

    const [dropdownVisible, setDropdownVisible] = useState(false);

    return (
        <>
            <nav id="main-nav">
                <img src={agmLogo} alt="agmaudyt logo"/>
                <ul className="nav-ul">
                    {
                        pathLinks.map((pathLink) => 
                            <li key={pathLink.url}><Link to={pathLink.url}>{pathLink.name}</Link></li>
                        )
                    }
                </ul>
                <HiMenu id="dropdown-icon" size="40px" onClick={() => setDropdownVisible(true)}/>
            </nav>
            <div id="dropdown-menu" className={dropdownVisible ? "visible" : "hidden"}>
                <div id="dropdown-logo-div">
                    <img src={agmLogo} alt="agmaudyt logo"/>
                    <GrFormClose size="55px" onClick={() => setDropdownVisible(false)}/>
                </div>
                <ul className="nav-ul">
                    {
                        pathLinks.map((pathLink) => 
                            <li key={pathLink.url}>
                                <Link to={pathLink.url} onClick={() => setDropdownVisible(false)}>
                                    {pathLink.name}
                                </Link>
                            </li>
                        )
                    }
                </ul>
            </div>
        </>
    );
}
