import "./Audit.css";

export default function Audit(){
    return (
        <section className="subpage-section" id="audit-section">
            <h1 className="subpage-title">Audyt</h1>
            <p>Kierujemy się zasadą, że od audytu należy oczekiwać potwierdzenia rzetelności i prawidłowości sprawozdań finansowych za minione okresy.</p>
            <p>Nasze podejście do badania polega na ciągłym pogłębianiu znajomości działalności naszych klientów oraz związanego z nią ryzyka. Ta wiedza pozwala nam opracowywać ukierunkowaną strategię badania i dostarczać klientom wartościowe wnioski, propozycje, zalecenia, jak też zapewnia klientom rzetelność rewizji sprawozdań finansowych</p>
            <p>Audyt, odbywa się zgodnie z obowiązującymi przepisami prawa, a zwłaszcza z:</p>

            <ul>
                <li>ustawą o rachunkowości,</li>
                <li>Międzynarodowymi Standardami Sprawozdawczości Finansowej,</li>
                <li>ustawą o biegłych rewidentach i ich samorządzie,</li>
                <li>Krajowymi Standardami Rewizji Finansowej wydanymi przez Krajową Radę Biegłych Rewidentów,</li>
                <li>Międzynarodowymi Standardami Rewizji Finansowej.</li>
            </ul>

            <p><strong>Etapy badania</strong></p>

            <ol>
                <li>Identyfikacja potrzeb i określenie zasad współpracy,</li>
                <li>Identyfikacja ryzyka działalności gospodarczej w danej branży oraz ryzyka badanej jednostki,</li>
                <li>Analiza sprawności systemu kontroli wewnętrznej,</li>
                <li>Przygotowanie planu badania i przeprowadzenie szczegółowych testów,</li>
                <li>Przygotowanie dokumentacji z badania i dokumentów finalnych,</li>
                <li>Ocena badania, sprawozdawczość.</li>
            </ol>

            <p>Wszelkie informacje uzyskane w związku z przesłaną ofertą oraz podczas badania są traktowane jako ściśle poufne. Badanie jest prowadzone w taki sposób, aby ograniczyć angażowanie kluczowych pracowników firmy do niezbędnego minimum.</p>
            <p><strong>Cel i zakres badania sprawozdania finansowego</strong></p>
            <p>Celem badania sprawozdania finansowego, zgodnie z przepisami art. 65 ust. 1 ustawy o rachunkowości, jest wyrażenie pisemnej opinii wraz z raportem o tym, czy sprawozdanie finansowe jest zgodne z wymagającymi zastosowania zasadami (polityką) rachunkowości oraz czy rzetelnie i jasno przedstawia, we wszystkich istotnych aspektach, sytuację majątkową i finansową, jak też wynik finansowy jednostki, której sprawozdanie finansowe jest badane. Biegły rewident ma zatem stwierdzić, czy sprawozdanie, jako całość i stanowiące podstawę jego sporządzenia księgi rachunkowe są wolne od uchybień, polegających na pominięciu lub zniekształceniu informacji ważnych (istotnych) dla czytelnika, i czy można uznać za wiarygodne (rzetelne, zgodne z wymagającymi zastosowania zasadami &lt;polityką&gt; rachunkowości) i jasne informacje zawarte w tym sprawozdaniu.</p>
            <p>Za sporządzenie zgodnego z obowiązującymi przepisami sprawozdania finansowego oraz sprawozdania z działalności odpowiedzialny jest kierownik jednostki. Kierownik jednostki oraz członkowie rady nadzorczej lub innego organu nadzorującego jednostki są zobowiązani do zapewnienia, aby sprawozdanie finansowe oraz sprawozdanie z działalności spełniały wymagania przewidziane w ustawie z dnia 29 września 1994 r. o rachunkowości (Dz. U. z 2009 r. Nr 152, poz. 1223, z późn. zm.), zwanej dalej „ustawą o rachunkowości”.</p>
            <p>Przeprowadzane przez biegłego rewidenta badanie, służące uzyskaniu podstaw do wyrażenia przez niego opinii, ma za zadanie potwierdzenie wiarygodności danych (stwierdzeń), zarówno liczbowych, jak i słownych, zawartych w sprawozdaniu finansowym i ich zgodności z wiążącymi jednostkę zasadami (polityką) rachunkowości.</p>
            <p>Zakres badania obejmuje możliwość zastosowania przez biegłego rewidenta sposobów (procedur) badania uznanych za niezbędne w danych warunkach.</p>
            <p>Wobec faktu przeprowadzenia badania sprawozdania finansowego w sposób wyrywkowy, pomimo prawidłowego ustalenia wielkości badanych próbek, na podstawie których następuje ocena ksiąg rachunkowych i sprawozdania finansowego, istnieje ryzyko niewykrycia ewentualnych nadużyć dokonanych w jednostce lub nieprawidłowości ksiąg rachunkowych lub sprawozdania finansowego, a także sporządzonych przez Zamawiającego rozliczeń podatków, jak również innych ewentualnych nieprawidłowości.</p>
            <p>Organizacja badania sprawozdania finansowego wymaga:</p>

            <ul>
                <li>zapewnienia biegłemu rewidentowi dostępu do wszystkich danych, dokumentacji i innych informacji niezbędnych do badania sprawozdania finansowego,</li>
                <li>zapewnienia biegłemu rewidentowi możliwości obserwacji spisu z natury znaczących składników majątku jednostki,</li>
                <li>współpracy pracowników jednostki z biegłym rewidentem,</li>
                <li>złożenia przez kierownictwo jednostki stosownych oświadczeń,</li>
                <li>ewentualnego włączenia rzeczoznawców do przeprowadzenia badania sprawozdania finansowego,</li>
                <li>współpracy pomiędzy biegłym rewidentem grupy a biegłymi rewidentami badającymi sprawozdania finansowe jednostek wchodzących w skład grupy kapitałowej.</li>
            </ul>

            <p>Jako podmiot uprawniony do badania sprawozdań finansowych zapewniamy spełnienie wymogu bezstronności i niezależności w stosunku do jednostki, której sprawozdanie finansowe podlega badaniu.</p>
            <p>W przypadku stwierdzenia w czasie badania sprawozdania finansowego ewentualnych istotnych nieprawidłowości w organizacji rachunkowości i kontroli wewnętrznej, nadużyć lub innych niedociągnięć, przedstawiamy je kierownikowi jednostki w formie listu poufnego.</p>
        </section>
    );
}