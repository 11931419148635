import "./Contact.css";

export default function Contact(){
    return (
        <section className="subpage-section" id="contact-section">
            <h1 className="subpage-title">Kontakt</h1>
            <div id="contact-container">
                <p><strong>AGM AUDYT SP. Z O.O.</strong></p>
                <p>adres: UL. UNRUGA 8<br/>
                25-228 KIELCE</p>
                <p>e-mail: <a href="mailto:biuro@agmaudyt.pl">biuro@agmaudyt.pl</a>,<br/>
                Tel. 41 344-53-37 , 512 147 689</p>
                <p>NIP 9591959324 REGON 360401088<br/>
                wpis do KRS pod nr 0000534421</p>
                <p>wpisa na listę podmiotów uprawnionych do badania sprawozdań finansowych prowadzoną przez Krajową Radę Biegłych Rewidentów pod numerem 3988</p>
            </div>
        </section>
    );
}