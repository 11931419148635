import {Route, Switch, useLocation} from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import routePaths from "./Utils/routePaths";
import {FadedDiv} from "./Utils/AnimatedRoute";

import Nav from "./Nav/Nav";

import './App.css';


function App() {

  const location = useLocation();
  // location needs to be inside BrowserRouter in order to work

  return (
    <>
      <Nav></Nav>
      <section id="main-section">
        <AnimatePresence exitBeforeEnter initial={false}>
          <Switch location={location} key={location.key}>
          {
            routePaths.map((path) => 
              <Route path={path.url} key={path.url}>
                <FadedDiv>
                  {<path.component/>}
                </FadedDiv>
              </Route>
            )
          }
          </Switch>
        </AnimatePresence>
      </section>
    </>
  );
}

export default App;
