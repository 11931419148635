import "./About.css";

import Photo from './img/anna-garbacz.png';

export default function About(){
    return (
        <section className="subpage-section" id="about-us-section">
            <h1 className="subpage-title">O nas</h1>
            
            <p><strong>   AGM AUDYT SP Z.O.O. </strong>jest niezależną <strong>firmą audytorską doradczą</strong>, kierowaną przez Biegłego Rewidenta <strong>Annę Garbacz, ACCA</strong> świadczącą szeroki wachlarz profesjonalnych usług związanych z badaniem sprawozdań finansowych i przekształceniami.</p>
            <p>Posiadamy wpis na listę podmiotów uprawnionych do badania sprawozdań finansowych, prowadzoną przez Krajową Radę Biegłych Rewidentów pod numerem 3988 i ubezpieczenie OC.</p>
            <p>Wysoką jakość świadczonych usług pragniemy zapewnić między innymi dzięki zaangażowaniu osób o odpowiednich kwalifikacjach i doświadczeniu.</p>
            <p>Długoletnie doświadczenie właściciela oraz indywidualne i partnerskie podejście do potrzeb klientów jest gwarancją wysokiej jakości wykonywanych przez nas usług oraz terminowego i sprawnego wykonania powierzonych nam zadań.</p>
            <p>Audyt będzie przeprowadzany przez zespół kierowany osobiście przez właściciela</p>
            
            <img src={Photo}/>
            <p style={{textAlign: "center"}}><strong>Anna Garbacz &#8211; Biegły Rewident  nr rej. 10248, ACCA</strong></p>
            <ul>
                <li>kwalifikacje ACCA,</li>
                <li>wpis do rejestru biegłych rewidentów prowadzonego przez Krajową Radę Biegłych Rewidentów uzyskany w 2004r.,</li>
                <li>licencja doradcy restrukturyzacyjnego,</li>
                <li>kwalifikacje specjalisty z zakresu wyceny przedsiębiorstw</li>
                <li>dyplom Magistra Ekonomii Uniwersytetu Łódzkiego,</li>
                <li>wiedza i wieloletnie doświadczenie w zakresie badania sprawozdań finansowych,</li>
                <li>gruntowna wiedza z zakresu polskich i międzynarodowych standardów rachunkowości poparta 18-letnim doświadczeniem zawodowym związanym z księgowością, finansami i zarządzaniem przedsiębiorstwem, uzyskane w spółkach giełdowych Echo Investment S.A. i Neuca S.A. oraz w Izbie Skarbowej w Kielcach.</li>
            </ul>
        </section>
    );
}