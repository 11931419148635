import agmIcon from "./img/agm-icon.png";
import {ReactComponent as BusinessIllustration} from './img/business-illustration.svg';

import "./Home.css";


export default function Home(){
    return (
        <>
            <header id="home-header" className="big-section">
                <img src={agmIcon} alt="Logo AGM audyt" />
                <h1><span>AGM</span> audyt</h1>
            </header>
            <section id="home-splitted-section">
                <div>
                    <p>Witamy na stronie naszej firmy. Zachęcamy do zapoznania się z naszą ofertą oraz konsultacji.</p>
                    <p>Misją naszej firmy jest świadczenie usług o najwyższym standardzie i tworzenie wartości dodanej dla naszego klienta. Naszą dewizą jest profesjonalne, indywidualne i kompleksowe podejście do potrzeb klienta.</p>
                    <p>Wykonujemy naszą pracę w poczuciu odpowiedzialności i zgodnie z posiadanymi kwalifikacjami, wiedzą fachową, profesjonalnie, rzetelnie, z najwyższą starannością oraz stosownie do przepisów prawa.</p>
                </div>
                <div className="img-div">
                    <BusinessIllustration alt="biznes ilustracja"/>
                </div>
            </section>
        </>
    );
}