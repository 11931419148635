import "./Services.css";

export default function Services(){
    return (
        <section className="subpage-section" id="services-section">
            <h1 className="subpage-title">Usługi</h1>
            <p><strong>Zakres oferowanych usług:</strong></p>
            <ul>
                <li>Badanie sprawozdań finansowych (rocznych jednostkowych i skonsolidowanych),</li>
                <li>Badanie planów przekształcenia,</li>
                <li>Badanie wyceny aktywów i pasywów bilansu dla potrzeb przekształcenia,</li>
                <li>Przekształcenia &#8211; pełne wsparcie doradcze,</li>
                <li>Aporty</li>
                <li>Wycena przedsiębiorstw</li>
            </ul>
            <p>Wychodząc naprzeciw potrzebom klientów, Firma Audytorska <strong>AGM AUDYT</strong> SP. Z O.O. działa w oparciu o wiedzę i kompetencje, stosując:</p>
            <ul>
                <li>przepisy i standardy odnoszące się do badań sprawozdań finansowych,</li>
                <li>wewnętrzne procedury kontroli jakości,</li>
                <li>zasady etyki zawodowej uchwalonej przez Krajową Radę Biegłych Rewidentów,</li>
                <li>Misją naszej firmy jest świadczenie usług o najwyższym standardzie i tworzenie wartości dodanej dla naszego klienta.</li>
                <li>Naszą dewizą jest profesjonalne, indywidualne i kompleksowe podejście do potrzeb klienta.</li>
            </ul>
            <p>Wykonujemy naszą pracę w poczuciu odpowiedzialności i zgodnie z posiadanymi kwalifikacjami, wiedzą fachową, profesjonalnie, rzetelnie, z najwyższą starannością oraz stosownie do przepisów prawa. Kierujemy się zasadami etyki zawodowej, a w szczególności obiektywizmem, niezależnością zawodową oraz zachowujemy w poufności wszelkie informacje o naszych klientach.</p>
            <p><strong>Deklarujemy gotowość komunikacji z audytorem wewnętrznym w języku angielskim.</strong></p>
        </section>
    );
}